$eshtri-typography: mat.define-typography-config(
  $font-family: "Circular, Almarai , sans-serif",
  $display-1:
    mat.define-typography-level(
      $font-weight: 900,
      $font-size: 10rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $display-2:
    mat.define-typography-level(
      $font-weight: 600,
      $font-size: 4.8rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $display-3:
    mat.define-typography-level(
      $font-weight: 900,
      $font-size: 3.6rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $display-4:
    mat.define-typography-level(
      $font-weight: 600,
      $font-size: 3rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $headline:
    mat.define-typography-level(
      $font-weight: 900,
      $font-size: 4.8rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $title:
    mat.define-typography-level(
      $font-weight: 900,
      $font-size: 3.9rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $subheading-2:
    mat.define-typography-level(
      $font-weight: 900,
      $font-size: 3.2rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $subheading-1:
    mat.define-typography-level(
      $font-weight: 700,
      $font-size: 2.6rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $body-1:
    mat.define-typography-level(
      $font-weight: 500,
      $font-size: 1.7rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
  $body-2:
    mat.define-typography-level(
      $font-weight: 900,
      $font-size: 1.7rem,
      $line-height: normal,
      $letter-spacing: normal,
    ),
);
.text-uppercase {
  text-transform: uppercase;
}
