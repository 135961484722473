/* cairo-regular - latin_arabic */
@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/CircularStd-Book.woff2") format("woff2"), url("../../assets/fonts/CircularStd-Book.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/CircularStd-Medium.woff2") format("woff2"),
    url("../../assets/fonts/CircularStd-Medium.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/CircularStd-Bold.woff2") format("woff2"), url("../../assets/fonts/CircularStd-Bold.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Circular";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/CircularStd-Black.woff2") format("woff2"), url("../../assets/fonts/CircularStd-Black.ttf") format("truetype");
  font-display: block;

}

@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Almarai-Light.woff2") format("woff2"), url("../../assets/fonts/Almarai-Light.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Almarai-Regular.woff2") format("woff2"), url("../../assets/fonts/Almarai-Regular.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Almarai-Bold.woff2") format("woff2"), url("../../assets/fonts/Almarai-Bold.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Almarai";
  font-style: normal;
  font-weight: 800;
  src: url("../../assets/fonts/Almarai-ExtraBold.woff2") format("woff2"), url("../../assets/fonts/Almarai-ExtraBold.ttf") format("truetype");
  font-display: block;
}